import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import {
  MintAclOptions,
  MintCommonModule,
  MintCommonModuleOptions,
  MintCoreModule,
} from '@bryllyant/mint-ngx';
import { Config } from '@fynvana/common';
import { ComponentLibModule } from '@fynvana/components';

import { NgIdleModule } from '@ng-idle/core';
import * as Sentry from '@sentry/angular';

import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing-module';
import { AppComponent } from './app.component';

const mintConfig = Config.getMintAppConfig(environment);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ComponentLibModule,
    MintCoreModule.forRoot(mintConfig.auth as MintAclOptions),
    MintCommonModule.forRoot(mintConfig.common as MintCommonModuleOptions),
    NzNotificationModule,
    SocialLoginModule,
    NgIdleModule.forRoot(),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: environment.env !== 'production',
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
