import {
  AsyncValidatorFn,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import {
  MintFormController,
  MintInputLayout,
  MintInputPhone,
  MintInputPhoneValue,
  MintInputText,
  MintPlainText,
  MintSelect,
} from '@bryllyant/mint-ngx';
import { MintFormAndFields, ValidatorUtil } from '@fynvana/common';

import { ContactPageComponent } from './contact-page.component';
import { authIssues, devIssues } from './utils';

export const contactInfoForm = (
  component: ContactPageComponent,
): MintFormAndFields => {
  const fullName = new MintInputText({
    name: 'fullName',
    type: 'text',
    size: 'large',
    placeholder: 'Full name',
    maxLength: 50,
    validator: Validators.compose([
      Validators.required,
      ValidatorUtil.regex(ValidatorUtil.NAME_REGEX, {
        whitespace: { error: 'Name is not proper format' },
      }),
    ]) as ValidatorFn | any,
    trim: false,
    onValidationError: (key: string, errors: ValidationErrors) => {
      switch (key) {
        case 'required':
          return 'Please enter a name';
        default:
          return errors?.error ?? 'Please enter a valid value';
      }
    },
  });

  const email = new MintInputText({
    name: 'email',
    type: 'text',
    size: 'large',
    placeholder: 'Email',
    maxLength: 50,
    validator: Validators.compose([
      Validators.required,
      ValidatorUtil.regex(ValidatorUtil.WHITESPACE_REGEX, {
        whitespace: { error: 'No empty whitespace' },
      }),
      ValidatorUtil.regex(ValidatorUtil.EMAIL_REGEX, {
        email: { error: 'Email is not proper format' },
      }),
    ]) as ValidatorFn | any,
    trim: false,
    onValidationError: (key: string, errors: ValidationErrors) => {
      switch (key) {
        case 'required':
          return 'Please enter an email';
        default:
          return errors?.error ?? 'Please enter a valid value';
      }
    },
  });

  const mobileNumber = new MintInputPhone({
    name: 'mobileNumber',
    size: 'large',
    placeholder: 'XXX-XXX-XXXX',
    defaultValue: { phoneNumber: '', countryCode: 'US' },
    validator: Validators.compose([
      Validators.required,
      Validators.minLength(5),
    ]) as ValidatorFn | any,
    asyncValidator: (async (control: UntypedFormControl) => {
      const value: MintInputPhoneValue = control.value;
      if (!value.phoneNumber) {
        return { required: '' };
      }

      return component.mobileNumberValidator.isValid(
        value.phoneNumber,
        value.countryCode,
      );
    }) as AsyncValidatorFn | any,
    onValidationError: (key: string, errors: ValidationErrors) => {
      switch (key) {
        case 'required':
          return 'Please enter a mobile number';
        default:
          return 'Please enter a valid phone number';
      }
    },
    layout: MintInputLayout.StandardLateral,
  });

  return {
    form: new MintFormController([fullName, email, mobileNumber]),
    fields: { fullName, email, mobileNumber },
  };
};

export const issueForm = (
  component: ContactPageComponent,
): MintFormAndFields => {
  const issueType = new MintSelect({
    name: 'issueType',
    placeholder: 'Select an issue type',
    size: 'large',
    showSearch: false,
    allowClear: true,
    validator: Validators.required,
    options: component.isAuthenticated ? devIssues : authIssues,
  });

  const description = new MintPlainText({
    name: 'description',
    size: 'large',
    maxLength: 1000,
    placeholder: 'Description',
    trim: false,
    validator: Validators.compose([Validators.required]) as ValidatorFn | any,
    onValidationError: (key: string, errors: ValidationErrors) => {
      switch (key) {
        case 'required':
          return 'Please enter a description';
        default:
          return errors?.error ?? 'Please enter a valid value';
      }
    },
  });

  return {
    form: new MintFormController([issueType, description]),
    fields: { issueType, description },
  };
};
