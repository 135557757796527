<fynvana-container>
  <main>
    <ng-container *ngIf="!ticketSubmitted">
      <div class="title blue mb-2">Contact Us</div>

      <!-- Start Contact Info Form -->
      <div *ngIf="contactInfoForm" class="mb-2">
        <mint-form
          nz-form
          [formGroup]="contactInfoForm"
          (onSubmit)="onSubmit()"
        >
          <!-- Full Name -->
          <div class="form-field inline">
            <div class="form-label">
              <label class="form-group-label">Full Name</label>
            </div>
            <div class="form-input">
              <mint-input-text
                [field]="contactInfoFormFields.fullName"
                formControlName="fullName"
              ></mint-input-text>
            </div>
          </div>

          <!-- Email -->
          <div class="form-field inline">
            <div class="form-label">
              <label class="form-group-label">Email</label>
            </div>
            <div class="form-input">
              <mint-input-text
                [field]="contactInfoFormFields.email"
                formControlName="email"
              ></mint-input-text>
            </div>
          </div>

          <!-- Mobile Number -->
          <div class="form-field inline">
            <div class="form-label">
              <label class="form-group-label">Mobile Number</label>
            </div>
            <div class="form-input">
              <mint-input-phone
                [field]="contactInfoFormFields.mobileNumber"
                formControlName="mobileNumber"
              ></mint-input-phone>
            </div>
          </div>
        </mint-form>
      </div>
      <!-- End Contact Info Form -->

      <div class="dark-heading-caption pt-2 pb-2">How can we help you?</div>

      <!-- Start Issue Form -->
      <mint-form
        *ngIf="issueForm"
        nz-form
        [formGroup]="issueForm"
        (onSubmit)="onSubmit()"
      >
        <!-- Issue Type -->
        <div class="form-field inline">
          <div class="form-label">
            <label class="form-group-label">Issue Type</label>
          </div>
          <div class="form-input">
            <mint-select
              [field]="issueFormFields.issueType"
              formControlName="issueType"
            ></mint-select>
          </div>
        </div>

        <!-- Description -->
        <div
          class="form-field inline"
          *ngIf="issueForm.controls.issueType.value || !isAuthenticated"
        >
          <div class="form-label">
            <label class="form-group-label">Description</label>
          </div>
          <div class="form-input contact-description">
            <mint-plain-text
              [field]="issueFormFields.description"
              formControlName="description"
            ></mint-plain-text>
          </div>
        </div>
      </mint-form>
      <!-- End Issue Form -->

      <!-- Start Form Actions -->
      <div>
        <fynvana-btn
          label="Cancel"
          (click)="location.back()"
          colorStyle="outline"
          classNames="my-1 mr-1"
        ></fynvana-btn>

        <fynvana-btn
          label="Submit"
          (click)="onSubmit()"
          [disabled]="
            isSubmitting ||
            !issueForm?.valid ||
            !!(contactInfoForm && !contactInfoForm.valid)
          "
          [loading]="isSubmitting"
          classNames="my-1"
        ></fynvana-btn>
      </div>
      <!-- End Form Actions -->
    </ng-container>

    <!-- Start Success Screen -->
    <ng-container *ngIf="ticketSubmitted">
      <div class="title blue mb-2">Contact Us</div>
      <div class="mb-2 dark-heading-caption">We received your message.</div>

      <div class="flex flex-col">
        Thank you for contacting us. We'll get back to you as soon as possible.

        @if (isAuthenticated) {
          <fynvana-btn
            (click)="navigate({ routerLink: '/dashboard' })"
            label="Go To My Dashboard"
            class="mt-3"
          ></fynvana-btn>
        } @else {
          @if (redirectedFrom === 'signup') {
            <fynvana-btn
              (click)="navigate({ routerLink: '/auth/signup' })"
              label="Return to Sign Up Page"
              class="mt-3"
            ></fynvana-btn>
          } @else if (
            redirectedFrom === 'login' || redirectedFrom === 'reset-password'
          ) {
            <fynvana-btn
              (click)="navigate({ routerLink: '/auth/login' })"
              label="Return to Login Page"
              class="mt-3"
            ></fynvana-btn>
          } @else {
            <fynvana-btn
              (click)="navigate({ routerLink: '/' })"
              label="Continue"
              class="mt-3"
            ></fynvana-btn>
          }
        }
      </div>
    </ng-container>
    <!-- End Success Screen -->
  </main>
</fynvana-container>
