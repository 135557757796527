import {
  MintConfig,
  MintLogLevel,
  MintPropertyNameCase,
  MintSessionScope,
} from '@bryllyant/mint-ngx';
import { logger } from 'nx/src/utils/logger';

let version: { versionTag: string; hash: string | null } | null = null;

try {
  version = require('../../../../version.json');
} catch (e) {
  logger.debug('version.json does not exist');
}

export const _default: MintConfig = {
  app: {
    baseHref: '/',
    version: version?.versionTag ?? 'dev',
    build: version?.hash ?? 'dev',
    baseUrl: 'http://localhost:4205/',
  },

  websiteBaseUrl: 'http://localhost:4201/',
  adminBaseUrl: 'http://localhost:4202/',
  developerBaseUrl: 'http://localhost:4203/',
  docsBaseUrl: 'http://localhost:4204/',
  clientBaseUrl: 'http://localhost:4205/',
  taxpayerBaseUrl: 'http://localhost:4206/',

  api: {
    baseUrl: 'http://localhost:3000/',
    clientIdHeaderName: 'fynvana-client-id',
    clientId: '4781b9d21cc64155b95a3813f78fcfa4',
    propertyNameCase: MintPropertyNameCase.Snake,
    apiVersion: '1.0.0',
    service: {
      auth: 'auth',
      user: 'users',
      geocoder: 'geocoder',
      helpdesk: {
        message: 'helpdesk/messages',
      },
      userRole: 'user-roles',
    },
  },

  seo: {
    title: 'Fynvana Client',
    description: '',
    author: 'Fynvana',
    twitterUsername: 'Fynvana',
  },

  logger: {
    level: MintLogLevel.ALL,
  },

  analytics: {
    google: {
      trackingId: 'G-GHG3TFB5ET',
    },
  },

  auth: {
    session: {
      scope: MintSessionScope.Memory,
    },
    idle: {
      idleStart: 15,
      timeout: 3600,
    },
  },

  components: {
    auth: {
      login: {
        route: '/auth/login',
        redirect: '/dashboard',
        socialLoginEnabled: false,
      },
      logout: {
        route: '/auth/logout',
        redirect: '/',
        redirectDelay: 500,
      },
      resetPassword: {
        route: '/auth/reset-password',
        redirect: '/auth/change-password',
        redirectDelay: 1000,
      },
      register: {
        route: '/auth/signup',
        redirect: '/auth/signup/register-mobile',
        acceptTerms: true,
        socialLoginEnabled: false,
      },
      registerMobile: {
        route: '/auth/register-mobile',
        redirect: '/dashboard',
        redirectDelay: 500,
      },
      verification: {
        route: '/auth/verification',
      },
    },
  },
  services: {},
};
