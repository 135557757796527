<!-- Start MFA -->
<ng-container *ngIf="type === 'mfa' && isTwoFactorOptionsVisible">
  <!-- Start Body -->
  <section class="verification-container two-factor-channel">
    <p>How would you like to receive your verification code?</p>
    <nz-radio-group [(ngModel)]="channel">
      <label
        class="two-factor-channel-option"
        nz-radio
        *ngFor="let channel of twoFactorChannels"
        [nzValue]="channel.type"
      >
        <ng-container *ngIf="channel.type === 'email'">
          An email to {{ channel.address || '' | mintEmailMask }}
        </ng-container>

        <ng-container *ngIf="channel.type === 'sms'">
          An SMS text message to
          {{
            channel.address || ''
              | mintPhoneFormat: undefined : undefined : true
          }}
        </ng-container>

        <ng-container *ngIf="channel.type === 'authenticator'">
          Use an authenticator app like Google Authenticator, Duo Mobile or
          Authy.
        </ng-container>
      </label>
    </nz-radio-group>

    <div class="btns">
      <fynvana-btn
        label="Back"
        colorStyle="outline"
        classNames="btn-wide mr-2 mb-1"
      ></fynvana-btn>
      <fynvana-btn
        label="Continue"
        classNames="btn-wide"
        [disabled]="!channel || !isInputValid()"
        (click)="initMfaChannel()"
      ></fynvana-btn>
    </div>
  </section>
  <!-- End Body-->
</ng-container>
<!-- End MFA -->

<!-- Start Code Entry -->
<ng-container *ngIf="type !== 'mfa' || !isTwoFactorOptionsVisible">
  <section class="verification-container">
    <h1>Verify your {{ channel === 'sms' ? 'mobile number' : 'email' }}</h1>

    <!-- Start Body -->
    <ng-container (paste)="onPaste($event)">
      <section>
        <div class="dark-heading mb-2" *ngIf="type === 'password-reset'">
          <ng-container *ngIf="channel === 'email'">
            If your account is found, a verification code will be emailed to
            <br />
            {{ user?.email || username | mintEmailMask }}
          </ng-container>

          <ng-container *ngIf="channel === 'sms'">
            If your account is found, a verification code will be texted to
            <br />
            {{
              user?.mobileNumber || ''
                | mintPhoneFormat: undefined : undefined : true
            }}
          </ng-container>
        </div>

        <div class="dark-heading mb-2" *ngIf="type !== 'password-reset'">
          We sent
          {{ channel === 'email' ? 'an email' : 'a text message' }}
          to:
          <ng-container *ngIf="channel === 'email'">
            {{ user?.email || username | mintEmailMask }}
          </ng-container>

          <ng-container *ngIf="channel === 'sms' && user">
            {{
              user.mobileNumber | mintPhoneFormat: undefined : undefined : true
            }}
          </ng-container>
        </div>

        <p>Please enter the 6 digit code you received</p>

        <div class="verification-code">
          <ng-container
            *ngFor="
              let char of inputVerificationCode;
              let i = index;
              trackBy: idxTracker
            "
          >
            <input
              type="number"
              [(ngModel)]="inputVerificationCode[i]"
              (paste)="onPaste($event)"
              (keydown)="onCodeKeyDown($event)"
              (keydown.enter)="triggerVerifyCode($event)"
            />
          </ng-container>
        </div>

        <div class="mt-3">
          <a (click)="resendCode()"
            >Didn't receive the code? Click to resend.</a
          >
        </div>

        <div class="mt-3">
          Please allow a few minutes to receive the code. If you're having
          trouble, please <a routerLink="/contact"> contact us</a>.
        </div>
        <!-- End Body -->
      </section>

      <div class="btns">
        <fynvana-btn
          label="Back"
          colorStyle="outline"
          classNames="btn-wide mr-2 mb-1"
        ></fynvana-btn>

        <fynvana-btn
          label="Continue"
          classNames="btn-wide"
          [disabled]="inputVerificationCode.join('').length !== 6"
          (click)="verifyCode()"
        ></fynvana-btn>
      </div>
    </ng-container>
  </section>
</ng-container>
<!-- End Code Entry -->
