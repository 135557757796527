<!-- Start Table Component -->
<main class="base-table-container responsive-table">
  <nz-table
    [nzData]="rows || []"
    [nzTotal]="totalItems"
    [nzPageSize]="pageLimit"
    [nzPageIndex]="pageIndex"
    (nzPageIndexChange)="pageChanged.emit($event)"
    [nzNoResult]="noResults"
    [nzFrontPagination]="!dynamicData"
    [nzShowPagination]="!disablePagination"
    nzTableLayout="fixed"
  >
    <div class="pagination-info" *ngIf="rows?.length && !disablePagination">
      {{ pageLimit * pageIndex - pageLimit + 1 }} -
      {{
        pageLimit * pageIndex > totalItems ? totalItems : pageLimit * pageIndex
      }}
      &nbsp; of &nbsp; {{ totalItems }}
    </div>

    <!-- Start Table Header -->
    <thead>
      <tr class="table-head">
        <th
          *ngFor="let col of cols"
          [nzWidth]="col.width ?? 'auto'"
          (click)="
            col.sortable
              ? sortChanged.emit(col.field)
              : $event.stopPropagation()
          "
        >
          <div
            class="flex"
            [ngClass]="{
              'cursor-pointer': col.sortable,
              'jc-center': col.centerLabel,
              'jc-between': !col.centerLabel
            }"
          >
            <div class="flex ai-center">
              <div class="table-head-label">
                <ng-container *ngIf="col.disableColTooltip">
                  {{ col.label }}
                </ng-container>
                <ng-container *ngIf="!col.disableColTooltip">
                  <span nz-tooltip [nzTooltipTitle]="col.label">
                    {{ col.label }}
                  </span>
                </ng-container>
              </div>
              <fynvana-icon
                icon="info"
                *ngIf="col.tip"
                nz-tooltip
                [nzTooltipTitle]="col.tip"
                [width]="20"
                [height]="20"
                color="#0785df"
                class="table-head-tip-icon"
              ></fynvana-icon>
            </div>
            <div
              *ngIf="col.sortable"
              class="table-sort-icon"
              [ngClass]="{
                'sort-icon-asc':
                  sort?.field === col.field && sort?.order === 'ASC',
                'sort-icon-desc':
                  sort?.field === col.field && sort?.order === 'DESC'
              }"
            >
              <fynvana-icon
                icon="sort"
                [height]="22"
                [width]="11"
              ></fynvana-icon>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <!-- End Table Header -->

    <!-- Start Table Body -->
    <tbody [ngClass]="{ 'no-data': !rows?.length && !hideNoDataDisplay }">
      <!-- Start Loader -->
      <fynvana-loader
        [isLoading]="isListLoading"
        positionStyle="absolute"
        size="sm"
      ></fynvana-loader>
      <!-- End Loader -->

      <ng-content></ng-content>
    </tbody>
    <!-- Start Table Body -->
  </nz-table>
</main>
<!-- Enad Table Component -->

<!-- Start No Results Template -->
<ng-template #noResults>
  <div class="table-no-data" *ngIf="!hideNoDataDisplay && rows?.length === 0">
    <div class="mb-1">
      <fynvana-icon
        icon="search-graph"
        [height]="100"
        [width]="100"
      ></fynvana-icon>
    </div>
    {{ noDataText }}
  </div>
</ng-template>
<!-- End No Results Template -->
