import { MintFormFieldOption } from '@bryllyant/mint-ngx';

export class TaxpayerHelpers {
  static get pastTaxYearOptions(): MintFormFieldOption[] {
    const pastTaxYearOptions: MintFormFieldOption[] = [];
    const currentDate = new Date();

    for (let i = 0; i < 25; i++) {
      const year = (currentDate.getFullYear() - i).toString();
      pastTaxYearOptions.push({ name: year, value: year });
    }

    return pastTaxYearOptions;
  }

  static get futureTaxYearOptions(): MintFormFieldOption[] {
    const futureTaxYearOptions: MintFormFieldOption[] = [];
    const currentDate = new Date();

    for (let i = 3; i >= 1; i--) {
      const year = (currentDate.getFullYear() + i).toString();
      futureTaxYearOptions.push({ name: year, value: year });
    }
    for (let i = 0; i < 25; i++) {
      const year = (currentDate.getFullYear() - i).toString();
      futureTaxYearOptions.push({ name: year, value: year });
    }

    return futureTaxYearOptions;
  }
}
