<fynvana-icon
  [icon]="'menu-more'"
  class="tertiary-color-action-icon"
  [height]="iconHeight"
  [width]="iconWidth"
  nz-dropdown
  nzTrigger="click"
  [nzDropdownMenu]="optionsMenu"
  (click)="$event.stopPropagation()"
  nz-tooltip
  nzTooltipTitle="More Options"
></fynvana-icon>

<nz-dropdown-menu #optionsMenu="nzDropdownMenu">
  <ul nz-menu nzMode="inline" class="quick-access-menu">
    <li
      style="padding-left: 15px"
      nz-menu-item
      *ngFor="let menuItem of menuItems"
      (click)="
        menuItem.disabled ? $event.stopPropagation() : determineAction(menuItem)
      "
      [nzDisabled]="menuItem.disabled"
      [ngClass]="{ disabled: menuItem.disabled }"
    >
      <a [ngClass]="{ disabled: menuItem.disabled }">
        {{ menuItem.title }}
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
