<nz-breadcrumb nzSeparator=">" *ngIf="breadcrumbs?.length">
  <nz-breadcrumb-item *ngFor="let crumb of breadcrumbs; let i = index">
    <a
      nz-tooltip
      [nzTooltipTitle]="
        crumb.title && crumb.title.length > 20 ? crumb.title : null
      "
      [routerLink]="crumb.routerLink"
      >{{ truncateString(crumb.title, 20) }}</a
    >
  </nz-breadcrumb-item>
</nz-breadcrumb>
