<!-- Start MFA Modal-->
<fynvana-modal
  *ngIf="type === 'mfa' && isTwoFactorOptionsVisible"
  wrapperClasses="verification-code-modal"
  [visible]="isVisible"
  [modalIsLoading]="isLoading"
  modalTitle="Choose Verification Type"
  submitLabel="Continue"
  [submitDisabled]="!channel || !isInputValid()"
  (submitted)="initMfaChannel()"
  (closed)="attemptCloseModal()"
  [closable]="closable"
  [hideCancel]="true"
  [statusAlertType]="statusAlert?.type"
  [statusAlertMessage]="statusAlertMessage"
>
  <!-- Start Modal Body -->
  <section class="modal-body two-factor-channel verification-code-modal">
    <p>How would you like to receive your verification code?</p>
    <nz-radio-group [(ngModel)]="channel">
      <label
        class="two-factor-channel-option"
        nz-radio
        *ngFor="let channel of twoFactorChannels"
        [nzValue]="channel.type"
      >
        <ng-container *ngIf="channel.type === 'email'">
          An email to {{ channel.address || '' | mintEmailMask }}
        </ng-container>

        <ng-container *ngIf="channel.type === 'sms'">
          An SMS text message to
          {{
            channel.address || ''
              | mintPhoneFormat: undefined : undefined : true
          }}
        </ng-container>

        <ng-container *ngIf="channel.type === 'authenticator'">
          Use an authenticator app like Google Authenticator, Duo Mobile or
          Authy.
        </ng-container>
      </label>
    </nz-radio-group>
  </section>
  <!-- End Modal Body-->
</fynvana-modal>
<!-- End MFA Modal-->

<!-- Start Code Entry Modal -->
<fynvana-modal
  *ngIf="type !== 'mfa' || !isTwoFactorOptionsVisible"
  wrapperClasses="verification-code-modal"
  [visible]="isVisible"
  [modalIsLoading]="isLoading"
  modalTitle="Enter Verification Code"
  submitLabel="Continue"
  [submitDisabled]="inputVerificationCode.join('').length !== 6"
  (submitted)="verifyCode()"
  (closed)="attemptCloseModal()"
  [closable]="closable"
  [hideCancel]="true"
  [statusAlertType]="statusAlert?.type"
  [statusAlertMessage]="statusAlertMessage"
>
  <!-- Start Modal Body-->
  <ng-container (paste)="onPaste($event)">
    <section class="verification-code-modal">
      <div class="dark-heading mb-2" *ngIf="type === 'password-reset'">
        <ng-container *ngIf="channel === 'email'">
          If your account is found, a verification code will be emailed to
          <br />
          {{ user?.email || username | mintEmailMask }}
        </ng-container>

        <ng-container *ngIf="channel === 'sms'">
          If your account is found, a verification code will be texted to <br />
          {{
            user?.mobileNumber || ''
              | mintPhoneFormat: undefined : undefined : true
          }}
        </ng-container>
      </div>

      <div class="dark-heading mb-2" *ngIf="type !== 'password-reset'">
        We sent
        {{ channel === 'email' ? 'an email' : 'a text message' }}
        to:
        <ng-container *ngIf="channel === 'email'">
          {{ user?.email || username | mintEmailMask }}
        </ng-container>

        <ng-container *ngIf="channel === 'sms' && user">
          {{
            user.mobileNumber | mintPhoneFormat: undefined : undefined : true
          }}
        </ng-container>
      </div>

      <p>Please enter the 6 digit code you received</p>

      <div class="verification-code">
        <ng-container
          *ngFor="
            let char of inputVerificationCode;
            let i = index;
            trackBy: idxTracker
          "
        >
          <input
            type="number"
            [(ngModel)]="inputVerificationCode[i]"
            (paste)="onPaste($event)"
            (keydown)="onCodeKeyDown($event)"
            (keydown.enter)="triggerVerifyCode($event)"
          />
        </ng-container>
      </div>

      <div class="mt-3">
        <a (click)="resendCode()">Didn't receive the code? Click to resend.</a>
      </div>

      <div class="mt-3">
        Please allow a few minutes to receive the code. If you're having
        trouble, please <a routerLink="/contact"> contact us</a>.
      </div>
      <!-- End Modal Body -->
    </section>
  </ng-container>
</fynvana-modal>
<!-- End Code Entry Modal -->

<ng-template #statusAlertMessage>
  <div *ngIf="statusAlert && statusAlert.slug === 'logout-warning'">
    Your phone/email needs to be verified, or else you'll be logged out.
    <br /><br />
    <a
      (click)="
        this.verificationCanceled.emit();
        this.navigate({ routerLink: '/auth/logout' })
      "
      >Logout Anyway</a
    >
  </div>

  <div [innerHTML]="statusAlert?.message" *ngIf="!statusAlert?.slug"></div>
</ng-template>
