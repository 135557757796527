<fynvana-modal
  [modalTitle]="confirmationTitle"
  [visible]="isVisible"
  [closable]="closable"
  [submitLabel]="confirmBtnLabel"
  [cancelLabel]="cancelBtnLabel"
  [statusAlertType]="type"
  [statusAlertMessage]="message"
  [statusAlertTitle]="messageTitle"
  (submitted)="handleSuccess.emit()"
  (closed)="closeModal.emit()"
>
</fynvana-modal>
