<fynvana-modal
  [modalTitle]="alertTitle || capitalizeFirst(type)"
  [modalSubTitle]="alertSubTitle"
  [visible]="isVisible"
  [submitLabel]="btnLabel"
  [hideCancel]="true"
  (submitted)="handleSuccess.emit(); closeModal.emit()"
  (closed)="handleSuccess.emit(); closeModal.emit()"
  [closable]="true"
  [statusAlertType]="type"
  [statusAlertMessage]="message"
  [statusAlertTitle]="messageTitle"
>
</fynvana-modal>
