<main class="{{ wrapperClasses }}">
  <!-- Start Breadcrumbs -->
  <div class="mb-1" *ngIf="breadcrumbs">
    <fynvana-breadcrumbs [breadcrumbs]="breadcrumbs"></fynvana-breadcrumbs>
  </div>
  <!-- End Breadcrumbs -->

  <!-- Start Main Header Content -->
  <div
    class="section-header"
    [ngClass]="{
      'column-aligned-no-buttons': !btn && !secondBtn,
      'column-aligned-single-button': btn && !secondBtn,
      'column-aligned-two-buttons': btn && secondBtn
    }"
  >
    <div class="flex ai-center pr-2 title-wrapper">
      <fynvana-icon
        *ngIf="includesBackNav"
        icon="back"
        color="#0785DF"
        nz-tooltip
        nzTooltipTitle="Back"
        (click)="location.back()"
        class="mr-1 cursor-pointer back-icon"
      ></fynvana-icon>
      <div
        class="title-wrapper"
        nz-tooltip
        [nzTooltipTitle]="isEllipsisActive ? pageTitle : null"
      >
        <p id="title" class="title blue truncate">{{ pageTitle }}</p>
      </div>
    </div>

    <div *ngIf="btn || secondBtn || link" class="button-col">
      <a *ngIf="link" class="mr-2" (click)="linkClicked.emit()">{{
        link.title
      }}</a>

      <fynvana-btn
        *ngIf="secondBtn"
        [label]="secondBtn.title"
        [disabled]="secondBtn.disabled ?? false"
        (click)="
          secondBtn.routerLink || secondBtn.url
            ? navigate(secondBtn)
            : secondBtnClicked.emit()
        "
        colorStyle="outline"
        class="mr-1"
      ></fynvana-btn>

      <fynvana-btn
        *ngIf="btn"
        [label]="btn.title"
        [disabled]="btn.disabled ?? false"
        (click)="btn.routerLink || btn.url ? navigate(btn) : btnClicked.emit()"
      ></fynvana-btn>
    </div>
    <!-- End Main Header Content -->
  </div>

  <!-- Start Tabs -->
  <div class="mt-3" *ngIf="navTabs">
    <fynvana-tabs [menuItems]="navTabs"></fynvana-tabs>
  </div>
  <!-- End Tabs -->
</main>
