import { MintFormFieldOption } from '@bryllyant/mint-ngx';

export const authIssues: MintFormFieldOption[] = [
  {
    name: 'Login Error',
    value: 'login-error',
  },
  {
    name: 'Authorization Code Error',
    value: 'auth-code-error',
  },
  {
    name: 'Password Reset Error',
    value: 'password-reset-error',
  },
  {
    name: 'Other',
    value: 'other',
  },
];

export const devIssues: MintFormFieldOption[] = [
  // {
  //   name: 'Billing Issue',
  //   value: 'billing-issue',
  // },
  {
    name: 'Security Settings',
    value: 'security-settings',
  },
  {
    name: 'Transcript Authorization',
    value: 'transcript-authorization',
  },
  {
    name: 'Other',
    value: 'other',
  },
];
