<nav class="tabs-container">
  <ul>
    <li
      *ngFor="let item of menuItems"
      [routerLink]="item.routerLink"
      routerLinkActive="active-tab"
    >
      {{ item.title }}
    </li>
  </ul>
  <hr />
</nav>
