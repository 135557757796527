import { Component, Input } from '@angular/core';
import { BaseController, BaseService, MenuItem } from '@fynvana/common';

@Component({
  selector: 'fynvana-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent extends BaseController {
  @Input() breadcrumbs: MenuItem[];

  constructor(public baseService: BaseService) {
    super(baseService);
  }
}
